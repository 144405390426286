<template>
  <div class="row">
    <div v-for="child in childrens" :key="child.id" class="col-xl-3 col-xxl-3 col-sm-6">
      <router-link :to="{
        name: 'Parent-Subjects-list',
        params: { student_id: child.user.id },
      }">
        <div class="widget-stat card bg-white">
          <div class="card-body">
            <div class="media">
              <div class="mr-3">
                <!-- <i class="la la-external-link"></i> -->
                <img v-if="child.img_url && child.img_url != null" :src="child.img_link"
                  style="width: 50px;border-radius: 50%;height: 50px;">
                <img v-else src="~@/assets/imgs/Profile_pic_placeholder.png"
                  style="width: 50px;border-radius: 50%;height: 50px;">
              </div>
              <div class="media-body text-white">
                <h5 class="text-blue">{{ child.user.fullname }}</h5>
                <small v-if="child.level">Level: {{ child.level.value }}</small><br>
                <small v-if="child.class">Class: {{ child.class.name }}</small><br>
                <small>View Subjects</small>
              </div>
            </div>
          </div>
        </div>
      </router-link>
    </div>
  </div>
</template>

<script>
import useSubject from "@/composables/subjects";
import { inject, ref, onBeforeMount } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
export default {
  setup() {
    const hasRole = inject("hasRole");
    const router = useRouter();
    const store = useStore();
    const disableBtn = ref(false);

    const { getParentChildrens, childrens } = useSubject();

    onBeforeMount(() => {
      //first: check the user Permission
      if (hasRole("Student")) {
        router.push({
          name: "Parent-Subjects-list",
          params: { student_id: store.getters.getLoggedInUser.user.id },
        });
      } else if (!hasRole("Parent")) {
        router.push({ name: "401" });
      } else {
        getParentChildrens();
      }
    });

    return {
      childrens,
      disableBtn,
    };
  },
};
</script>